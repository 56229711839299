/*!
 * This copies the functionality of bootstrap.scss
 */

// @import '_fonts.scss';

@import '../../node_modules/bootstrap/scss/functions';

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
// @import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';

@keyframes flashing {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}


.Toastify {
  z-index: 9999;
}

body {
  padding-right: 0 !important;
}

body #hubspot-messages-iframe-container {
  z-index: 9990;
}

.MuiDrawer-root {
  z-index: 9991;
}

.MuiModal-root {
  z-index: 9992;
}

.MuiTooltip-popper,
.MuiPickersPopper-root {
  z-index: 9999;
}

.MuiAutocomplete-popper {
  z-index: 9999;
}

.slick-slider {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
.slick-list.draggable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Toastify__toast {
  background: #fff !important;
  color: black !important;
  border-radius: 9px;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12) !important;
  padding: 8px !important;
  div {
    font-family: Inter !important;
    font-size: 16px;
    font-weight: 600;
  }
}

// .collection-sidebar-menu {
//   z-index: 1000;
// }
// .collection-sidebar-menu-content,
// .email-popup-content {
//   background: #ffa093 !important;
//   box-shadow: 0px 0px 6px #ffa093 !important;
//   border: none !important;
//   border-radius: 12px;
//   padding: 12px !important;

//   .collection-sidebar-menu-arrow,
//   .email-popup-arrow {
//     background: #ffa093 !important;
//     box-shadow: 0px 0px 6px #ffa093 !important;
//     border: none !important;
//   }
// }

// .Toastify__toast.Toastify__toast--default {
//   background: #ffa093 0% 0% no-repeat padding-box;
//   box-shadow: 0px 0px 30px #ffd2cc;
//   border-radius: 12px;
//   color: #272727;
//   padding: 26px;

//   .Toastify__close-button--default {
//     height: 60px;
//     padding-left: 12px;
//     color: #272727;
//   }

//   .Toastify__toast-body {
//     font-size: 15px;
//     line-height: 20px;
//     font-family: 'Roboto', sans-serif;
//   }
// }

// @import '_typography';

// @import '~bootstrap/scss/type';
// @import "~bootstrap/scss/images";
// @import "~bootstrap/scss/code";
// @import '~bootstrap/scss/grid';
// @import '~bootstrap/scss/tables';
// @import '~bootstrap/scss/forms';
// @import '~bootstrap/scss/buttons';
// @import "~bootstrap/scss/transitions";
// @import '~bootstrap/scss/dropdown';
// @import '~bootstrap/scss/button-group';
// @import '~bootstrap/scss/input-group';
// @import '~bootstrap/scss/custom-forms';
// @import '~bootstrap/scss/nav';
// @import '~bootstrap/scss/navbar';
// @import '~bootstrap/scss/card';
// @import "~bootstrap/scss/breadcrumb";
// @import '~bootstrap/scss/pagination';
// @import '~bootstrap/scss/badge';
// @import "~bootstrap/scss/jumbotron";
// @import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
// @import '~bootstrap/scss/media';
// @import '~bootstrap/scss/list-group';
// @import "~bootstrap/scss/close";
// @import '~bootstrap/scss/modal';
// @import "~bootstrap/scss/tooltip";
// @import "~bootstrap/scss/popover";
// @import "~bootstrap/scss/carousel";
// @import '~bootstrap/scss/utilities';
// @import "~bootstrap/scss/print";

// $borderWidth: 2px;

// #root {
// 	&:after {
// 		position: absolute;
// 		display: none;
// 		top: 0px;
// 		left: 0px;
// 		color: black;
// 	}

// 	@include media-breakpoint-up(sm) {
// 		&:after {
// 			content: 'sm';
// 		}
// 	}
// 	@include media-breakpoint-up(md) {
// 		&:after {
// 			content: 'md';
// 		}
// 	}
// 	@include media-breakpoint-up(lg) {
// 		&:after {
// 			content: 'lg';
// 		}
// 	}
// 	@include media-breakpoint-up(xl) {
// 		&:after {
// 			content: 'xl';
// 		}
// 	}
// }

// body {
// 	background: #d9d9d9;
// 	color: rgba(0, 0, 0, 0.8);
// 	transition: all 250ms;
// }

// .container {
//     padding: 44px;
//     z-index: 1000;

//     &.top {
//         background-color: #ffffff;
//         z-index: 3;
//         position: relative;
//     }
// }

// .bg-image {
//     position: relative;
//     padding-bottom: 290px;

//     background-repeat: no-repeat;
//     background-position: bottom center;
//     background-image: url("data:image/svg+xml,%3Csvg width='966' height='270' version='1' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23000000' stroke-width='3' fill='none' fill-rule='evenodd'%3E%3Cpath stroke-linecap='square' d='M964 69H831v133h33V36h34v66H665V69H533V2h33v34h-66v33h-34v33H68V2h34v34H35v33H2'/%3E%3Cpath d='M798 69h-33v66h133v33H765v34h33zM732 69h-33v66h33zM732 202h-33v33h33zM765 235v33h33v-33zM632 36h-33v166h-33v-67h66v33h-99v-66h99zM533 202h-33v33h33zM433 36h-33v166h-33V69h66z'/%3E%3Cpath d='M433 135H301V69h33v133h-33v-34h132zM334 235v33h-33v-33zM267 69v99h-66v34h66v33h-33V69zM201 69v66h-33V36h-33v99H68v33h34V69zM68 202v33H35v-33z'/%3E%3C/g%3E%3C/svg%3E");
//     z-index: 0;

//     &:before {
//         content: " ";
//         border: 3.3px solid black;
//         border-right: none;
//         width: 160px;
//         height: calc(100% -  550px);
//         position: absolute;
//         bottom: 200px;
//         left: calc(50% - 608px);
//         z-index: 1;
//         transform: scale(1.001);
//     }

//     &:after {
//         content: " ";
//         border: 3.3px solid black;
//         border-left: none;
//         width: 160px;
//         height: calc(100% -  550px);
//         position: absolute;
//         bottom: 200px;
//         left: calc(50% + 448px);
//         z-index: 1;
//         transform: scale(1.001);
//     }
// }

// .App {
// 	text-align: center;
// }

// .App-logo {
// 	animation: App-logo-spin infinite 20s linear;
// 	height: 40vmin;
// 	pointer-events: none;
// }

// // .App-header {
// // 	background-color: #282c34;
// // 	min-height: 100vh;
// // 	display: flex;
// // 	flex-direction: column;
// // 	align-items: center;
// // 	justify-content: center;
// // 	font-size: calc(10px + 2vmin);
// // 	color: white;
// // }

// .App-link {
// 	color: #61dafb;
// }

// .mail {
// 	font-weight: 600;
// 	display: block;
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// 	white-space: nowrap;
// }
// .listOutter {
// 	margin-bottom: 15px;
// 	margin-top: 30px;
// 	flex-direction: row;
// 	list-style: none;
// 	display: flex;
// 	-webkit-box-orient: horizontal;
// 	-webkit-box-direction: normal;
// }
// .listInnerChild {
// 	font-size: 16px;
// 	margin-right: 40px;
// 	color: #262626;
// 	display: list-item;
// 	font-weight: 600;
// 	vertical-align: baseline;
// }

// .listInner {
// 	font-size: 16px;
// 	margin-right: 40px;
// 	margin-left: -40px;
// 	color: #262626;
// 	display: list-item;
// 	font-weight: 600;
// 	vertical-align: baseline;
// }

// .username {
// 	font-size: 28px;
// 	line-height: 32px;
// 	font-weight: 300;
// 	color: #262626;
// 	display: block;
// 	overflow: hidden;
// 	text-overflow: ellipsis;
// 	white-space: nowrap;
// }

// .displayName {
// 	display: inline;
// 	font-weight: 600;
// 	color: #262626;
// 	font-size: 16px;
// 	line-height: 24px;
// }

// .profilePic {
// 	margin-top: 100;
// }

// @keyframes App-logo-spin {
// 	from {
// 		transform: rotate(0deg);
// 	}
// 	to {
// 		transform: rotate(360deg);
// 	}
// }

// @import '_forms';

// .navbar-new {
// 	background-color: #efeff4;
// 	margin-bottom: rem-calc(21);

// 	.btn-nav {
// 		color: #8e8e93;
// 		font-size: rem-calc(14);
//         font-weight: normal;
//         font-family: $fontFamilyBold;
// 		letter-spacing: 1.08px;
// 		line-height: rem-calc(15);
// 		text-align: center;
// 		background-color: transparent;
// 		border: 4px solid transparent;
// 		text-transform: uppercase;
// 		display: flex;
// 		align-items: center;
// 		justify-content: center;
// 		cursor: pointer;

// 		> span {
// 			position: relative;
// 			top: 2px;
// 		}

// 		&:hover {
// 			background-color: #696969 !important;
// 			color: #ffffff !important;
// 		}

// 		&.active {
// 			color: #ffffff;
// 			background-color: #dedd2c;
// 			border-color: #efeff4;
// 		}
// 	}
// }

// .collection-tools {
//     color: #d1d1d6;
//     svg:not(.lighten) {
//         path {
//             fill: #d1d1d6;
//         }
//     }
//     svg.disabled {
//         path {
//             fill: #ececec;
//         }
//     }
// }

// .table {
// 	color: $textColor;
//     font-size: $fontSizeContent;
//     font-family: $fontFamilyRegular;
// 	line-height: $lineHeight;
// 	word-break: break-word;

// 	th {
// 		font-weight: bold;
// 		text-transform: uppercase;
//     }

// 	.result-list-row {
// 		position: relative;

// 		&.header {
//             word-break: normal;
//             font-family: $fontFamilyBold;
//             text-transform: uppercase;
//             font-size: rem-calc(14);

//             .table-subtitle {
//                 // font-family: $fontFamilyRegular;
//                 // font-size: $fontSizeSub;
//                 font-size: rem-calc(12);
//                 font-family: $fontFamilyReadable;
//             }
// 		}

// 		a {
// 			color: #000000;
// 		}

// 		padding-bottom: rem-calc(10);
// 		padding-top: rem-calc(7);
// 		margin-bottom: rem-calc(7);

// 		&:after {
// 			content: ' ';
// 			position: absolute;
// 			bottom: 0px;
// 			left: $grid-gutter-width / 2;
// 			display: inline-block;
// 			width: calc(100% - #{$grid-gutter-width});
// 			height: 0px;
// 			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
// 		}

// 		.comment-box {
// 			padding-left: $paddingDefault / 2;
// 			&:not(.visible) {
// 				color: transparent !important;
// 			}

// 			border: 1px dashed transparent;

// 			&:hover {
// 				color: $textColorMuted !important;
// 				border: 1px dashed $textColorMuted;
// 			}
// 		}

// 		&:hover {
// 			.comment-box {
// 				color: $textColorMuted !important;
// 				border: 1px dashed $textColorMuted;
// 			}

// 			.collection-tools {
// 				color: rgba(0, 0, 0, 0.5);
// 				svg:not(.lighten) {
// 					path {
// 						fill: rgba(1, 0, 0, 0.5);
// 					}
//                 }
// 				svg.decline-icon {
// 					path {
// 						fill: #ff3b30;
// 					}
// 				}
// 				.confirm-icon {
// 					path {
// 						fill: #4cd964;
// 					}
// 				}
// 			}
// 		}
// 	}
// }

// footer {
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;
// 	position: relative;

// 	a {
// 		font-family: $fontFamilyReadable;
// 		font-size: $fontSizeSub;
// 		padding: $paddingDefault;
// 		color: #424242;
// 	}

// 	&:before {
// 		content: ' ';
// 		height: 0px;
// 		width: 35%;
// 		border-bottom: 3px solid #424242;
// 		position: absolute;
// 		top: 0px;
// 	}

// 	&:after {
// 		content: ' ';
// 		height: 0px;
// 		width: 24%;
// 		border-bottom: 3px solid #424242;
// 		position: absolute;
// 		bottom: 0px;
// 	}
// }

// .copyright {
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;

// 	font-family: $fontFamilyReadable;
// 	font-size: $fontSizeSub;
// 	padding: $paddingDefault;
// 	color: #424242;
// }

// .modal-dialog {
//     top: 10%;
//     max-width: 620px;

// 	.modal-content {
// 		background-color: #d9d9d9;
//         padding: $paddingDefault * 2;

//         .modal-header {
//             padding: 1rem;
//             padding-top: 0rem;
//             font-family: $fontFamilyReadable;
//         }

// 		.modal-body {
// 			padding-top: 0;
// 			padding-bottom: 0;
// 		}

// 		.modal-body,
// 		.modal-header,
// 		.modal-footer {
// 			padding-left: 0px;
// 			padding-right: 0px;
// 			border-bottom-width: $borderWidth;
// 			border-top-width: $borderWidth;
// 			font-size: $fontSizeContent;
// 			line-height: $lineHeight;
// 			cursor: pointer;

// 			.dropdown-option {
// 				padding: $paddingDefault;
// 				background-color: opacify($textColor, 1);
// 				color: opacify(invert($textColor), 1);
// 				margin-bottom: $paddingDefault;

// 				span {
// 					vertical-align: $verticalAlignDefault;
// 					display: inline-block;
// 					margin-bottom: -0.125rem;
// 				}

// 				&:last-of-type {
// 					margin-bottom: 0px;
// 				}
// 			}

// 			input[type='text'] {
// 				margin: $paddingDefault;
// 				margin-left: 0;
// 				margin-right: 0;
// 				padding: rem-calc(5);
// 				padding-top: rem-calc(8);
//                 width: 100%;
//                 font-family: $fontFamilyReadable;
// 			}
// 		}
// 	}
// }

// .collection-tools {
// 	display: flex;
//     flex-direction: column;
//     position: relative;
//     top: -2px;

//     svg {
//         cursor: pointer;
//     }

// 	.add-remove {
// 		position: relative;
// 		display: flex;
// 		justify-content: space-evenly;
// 		align-items: center;
// 		width: rem-calc(130);

// 		svg {
// 			margin: 10px;
// 			margin-top: 0px;
// 		}

// 		.confirm {
// 			position: absolute;
// 			top: 0px;
// 			left: 0px;
// 			width: 100%;
//             height: 100%;
// 			background-color: white;
// 			display: flex;
// 			flex-direction: row;
// 			justify-content: space-between;
//             flex-wrap: nowrap;

// 			svg {
// 				padding: 0;
// 				margin: rem-calc(5);
// 				position: relative;
// 				top: rem-calc(-3);
// 			}

// 			.decline-icon {
//                 padding-top: 2px;
// 				path {
// 					fill: #ff3b30;
// 				}
// 			}

// 			.confirm-icon {
//                 padding-top: 2px;
// 				path {
// 					fill: #4cd964;
// 				}
// 			}
// 		}

// 		.confirm-text {
// 			font-size: rem-calc(10);
// 			text-transform: uppercase;
// 			line-height: rem-calc(15);
// 			text-align: center;
// 			padding-top: rem-calc(9);
// 		}

// 		.add-to-list-icon {
//             position: relative;
//             top: 2px;

// 			.collection-count-badge {
// 				background-color: white;
// 				// border: 1px solid #8E8E93;
// 				position: absolute;
// 				top: -5px;
//                 left: 0px;
// 				border-radius: 200%;
// 				height: rem-calc(12);
// 				width: rem-calc(12);
// 				font-size: rem-calc(12);

// 				span {
// 					position: relative;
// 					top: rem-calc(-5);
// 					left: rem-calc(2);
// 					font-size: 11px;
// 				}
// 			}
// 		}
// 	}
// }

// .star-rating {
// 	display: flex;
// 	justify-content: space-evenly;
// }

// .profile-dialog {
// 	.modal-content {
// 		padding-left: rem-calc(117);
// 		padding-right: rem-calc(117);
// 	}
// }

// .profile {
// 	.push-md {
// 		margin-left: 50%;
// 		@include media-breakpoint-up(lg) {
// 			margin-left: inherit;
// 		}
// 	}
// 	.username {
// 		font-size: rem-calc(24);
// 		line-height: rem-calc(27);
// 		font-family: $fontFamilyReadable;
// 	}
// 	.display-name {
// 		font-size: rem-calc(16);
// 		line-height: rem-calc(19);
// 	}
// 	.display-location {
// 		font-size: rem-calc(12);
// 		line-height: rem-calc(15);
// 		font-family: $fontFamilyReadable;
// 	}
// 	.profile-stats {
// 		padding-left: 0;

// 		li {
// 			display: inline-block;
// 			padding: $paddingDefault/2;
// 			padding-left: 0;
// 			padding-right: $paddingDefault;
// 			font-size: rem-calc(16);

// 			font-family: $fontFamilyReadable;

// 			b {
// 				font-family: $fontFamilyBold;
// 			}

// 			&:first-of-type {
// 				padding-left: 0px;
// 			}
// 		}
// 	}
// 	.description {
// 		font-family: $fontFamilyReadable;
// 		font-size: rem-calc(14);
// 		line-height: rem-calc(16);
// 		margin-top: $paddingDefault;
// 	}
// 	.description-icon-container {
// 		margin-top: $paddingDefault;
// 		position: relative;
// 		.separator {
// 			padding: $paddingDefault;
// 			.line {
// 				border: 3px solid #979797;
// 				height: rem-calc(35);
// 			}
// 		}
// 	}
// 	.instagram,
// 	.website,
// 	.email {
// 		margin-top: 0px;
// 		a {
// 			display: inline-block;
// 			margin-top: rem-calc(4);
// 			margin-bottom: rem-calc(4);
// 			font-family: $fontFamilyReadable;
// 			font-size: rem-calc(14);
// 			color: rgba(0, 0, 0, 0.5) !important;

// 			&:hover {
// 				color: $textColor !important;
// 				text-decoration: none;
// 			}

// 			span {
// 				position: relative;
// 				bottom: rem-calc(-2);
// 			}
// 		}

// 		svg {
// 			margin-right: rem-calc(15);
// 		}
// 	}

// 	.comment-row {
// 		position: relative;

// 		.comment-box {
// 			position: relative;
// 			min-height: rem-calc(42);
// 			background-color: #ececec;
// 			padding: rem-calc(20) rem-calc(16);
// 			margin-top: rem-calc(20);

// 			textarea {
// 				background-color: #ececec;
// 			}

// 			border: 1px dashed transparent;

// 			&:hover {
// 				color: $textColorMuted !important;
// 				border: 1px dashed $textColorMuted;
// 			}

// 			span {
// 				position: relative;
// 				top: 3px;
// 			}
// 		}

// 		label {
// 			position: absolute;
// 			top: rem-calc(16);
// 		}

// 		.chevron-right,
// 		.chevron-left {
// 			position: absolute;
// 			top: calc(50% - #{rem-calc(10)});
// 		}

// 		.chevron-right {
// 			right: rem-calc(-72);
// 		}

// 		.chevron-left {
// 			transform: rotate(180deg);
// 			left: rem-calc(-72);
// 		}
// 	}

// 	.user-profile-detail-score {
// 		display: flex;
// 		justify-content: space-evenly;
// 		background-color: #ececec;
// 		padding: rem-calc(20) rem-calc(16);
// 		margin-top: rem-calc(20);
// 		position: relative;

// 		label {
// 			position: absolute;
// 			top: rem-calc(20);
// 			left: rem-calc(16);
// 		}

// 		.profile-score-with-label {
// 			margin-top: rem-calc(2);

// 			small {
// 				display: block;
// 				text-align: center;
// 				font-size: rem-calc(11);
// 				margin-top: rem-calc(8);
// 			}
// 		}
// 	}
// }

// .profile-pic {
// 	width: 100%;
// 	height: auto;
// }

// .profile-grid {
// 	margin-left: -rem-calc(0.75);
//     margin-right: -rem-calc(0.75);

// 	.grid-cell {
//         //padding-left: $paddingDefault / 4;
// 		//padding-right: $paddingDefault / 4;
// 		padding: rem-calc(1.5);
//         position: relative;

//         .overlay {
//             position: absolute;
//             top: 0px;
//             left: 0px;
//             width: 100%;
//             height: 100%;
//             opacity: 0;
//             background-color: rgba(0, 0, 0, 0.5);
//             color: #fff;
//             font-size: 16px;

//             display: flex;
//             align-items: center;
//             justify-content: center;

//             &:hover {
//                 opacity: 1;
//             }
//         }
// 	}
// }

// .profile-score {
// 	position: relative;
// 	display: flex;
// 	justify-content: center;
// 	align-items: center;

// 	.rating-meter {
// 		position: absolute;
// 		top: 0px;
// 		left: 0px;
// 		width: 100%;
// 		height: 100%;
// 	}

// 	.profile-score-value {
// 		display: inline-block;
// 		margin-bottom: rem-calc(-7);
// 		font-family: $fontFamilyReadable;
// 		font-size: rem-calc(18);
// 	}
// }

// .profile-pic-with-score {
// 	position: relative;

// 	.profile-pic-and-meter {
// 		.profile-pic {
// 			position: absolute;
// 			top: 25px;
// 			left: 25px;
// 		}

// 		.rating-meter {
// 			position: absolute;
// 			top: 0px;
// 			left: 0px;
// 			width: 180px;
// 			height: 180px;
// 		}
// 	}

// 	.label {
// 		position: absolute;
// 		display: block;
// 		top: 190px;
// 		left: 0px;
// 		width: 100%;
// 		text-align: center;
// 		font-family: $fontFamilyBold;
// 		font-size: rem-calc(14);
// 	}

// 	.score {
// 		position: absolute;
// 		top: 220px;
// 		left: 0px;
// 		text-align: center;
// 		font-size: rem-calc(30);
// 		width: 100%;
// 	}
// }

// @import '_creatable_multi_select';

// .search-keyword-tabs {
// 	display: flex;
// 	justify-content: space-between;

// 	.nav-item {
// 		background-color: #d9d9d9;
// 		border: none;
// 		flex: 0 0 33.334%;
// 		text-align: center;
// 		margin-bottom: rem-calc(-7);
// 		color: #575757;
// 		font-size: rem-calc(11);
// 		text-transform: uppercase;

// 		&.active {
// 			background-color: #ffffff;
// 		}
// 	}
// }

// .modal-list-select {
// 	ul {
// 		list-style-type: none;
// 		padding-left: 0px;
// 	}

// 	.modal-header {
// 		border: none;
// 		text-align: center;
// 		color: #424242;
// 		// text-transform: uppercase;
// 		font-size: rem-calc(18) !important;
// 		padding-bottom: 0px;

// 		.modal-title {
// 			margin: 0 auto;
// 			font-size: rem-calc(18) !important;
// 		}
// 	}

// 	.modal-body {
// 		padding-top: rem-calc(8);
// 		input {
// 			height: rem-calc(59);
//             padding: rem-calc(12);
//             font-family: $fontFamilyReadable;
// 		}
// 	}
// }

// .modal-list-select-option {
// 	background-color: #ececec;
// 	position: relative;
// 	color: #2f2f2f;
// 	font-size: rem-calc(14);
// 	text-transform: uppercase;
// 	padding-left: rem-calc(12);
// 	padding-top: rem-calc(10);
// 	padding-bottom: rem-calc(10);
// 	margin-top: rem-calc(5);
// 	margin-bottom: rem-calc(5);
//     height: rem-calc(35);
//     font-family: $fontFamilyReadable;

//     cursor: pointer;

// 	span {
// 		position: relative;
// 		top: rem-calc(0);
// 	}

// 	// &:after {
// 	//     content: "+";
// 	//     position: absolute;
// 	//     right: rem-calc(15);
// 	//     top: rem-calc(8);
// 	//     font-size: rem-calc(30);
// 	//     color: lighten(#2F2F2F, 50%)
//     // }

// 	&.selected {
// 		background-color: #dedd2c;
// 		color: #ffffff;

// 		// &:after {
// 		//     content: "-";
// 		// }

// 		&:after {
//             content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24'%3E%3Cpath d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z' fill='%23ffffff' /%3E%3C/svg%3E");
//             position: absolute;
//             right: rem-calc(15);
//             top: rem-calc(10);
//             font-size: rem-calc(22);
//             color: lighten(#2f2f2f, 50%);
//         }
// 	}
// }

// // @Hermann: Sorry for that!
// /* The switch - the box around the slider */
// .switch {
// 	position: relative;
// 	display: inline-block;
// 	width: 30px;
// 	height: 17px;
// }

// /* Hide default HTML checkbox */
// .switch input {
// 	opacity: 0;
// 	width: 0;
// 	height: 0;
// }

// .match-switch, .email-switch {
//     span {
//         font-family: $fontFamilyReadable;
//     }
// }

// /* The slider */
// .slider {
// 	position: absolute;
// 	cursor: pointer;
// 	top: 0;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	background-color: #ccc;
// 	-webkit-transition: 0.4s;
// 	transition: 0.4s;
// }

// .slider:before {
// 	position: absolute;
// 	content: '';
// 	height: 13px;
// 	width: 13px;
// 	left: 2px;
// 	bottom: 2px;
// 	background-color: white;
// 	-webkit-transition: 0.4s;
// 	transition: 0.4s;
// }

// input:checked + .slider {
// 	background-color: #dedd2c;
// }

// // input:focus + .slider {
// // 	box-shadow: 0 0 1px #dedd2c;
// // }

// input:checked + .slider:before {
// 	-webkit-transform: translateX(13px);
// 	-ms-transform: translateX(13px);
// 	transform: translateX(13px);
// }

// .email-switch {
//     input:checked + .slider {
//         background-color: #CCCCCC;
//     }
//     input:not(:checked) + .slider {
//         background-color: #dedd2c;
//     }
// }

// /* Rounded sliders */
// .slider.round {
// 	border-radius: 17px;
// }

// .slider.round:before {
// 	border-radius: 50%;
// }

// .followerChart {
// 	height: 100%;
// 	display: flex;
// 	align-items: center;
// 	justify-content: center;
// 	margin-top: rem-calc(-27);
// 	margin-bottom: rem-calc(12);
// }
